import React, { useContext, useEffect, useState } from 'react'
import adminAxios from '../../config/axios'
import { ApiContext } from '../../context/apiContext'
import AdminOffers from './AdminOffers';

export const Offers = () => {

  const [ auth, setAuth] = useContext(ApiContext);
  const [offers, setOffers] = useState([]);

  useEffect( () => {

    if(auth.token !== '' && auth.auth){

        consultarOffers();
    }

  }, [offers]);

  const consultarOffers = async () => {

    try {

      const peticion = await adminAxios.get('/all_offers', {
        headers: {
          Authorization: `Bearer ${auth.token}`                          
        }
      });

      setOffers(peticion.data);   
       
      
    } catch (error) {
      console.log(error)      
    }  

  }
 
  return (
    
    <AdminOffers offers={offers}/>
  )
}


