import  React, { useContext } from 'react';
import { format, compareAsc } from "date-fns";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import adminAxios from '../../config/axios';
import { ApiContext } from '../../context/apiContext';
import Swal from 'sweetalert2';


let arreglo = [] 

export default function AdminOffers({offers}) {
    
     arreglo = offers.offers;
     const [ auth, setAuth] = useContext(ApiContext);

     const onClickdeleteOffer = (id, image) => {        

        Swal.fire({
            title: "Delete offer?",      
            showCancelButton: true,
            confirmButtonText: "Yes, Delete",
            
          }).then((result) => {

            if (result.isConfirmed) {

                deleteOffer(id, image);
            }

        });

     }

     const deleteOffer = async(id, image) => {     

        const nombre = image;
        const split = nombre.split('/'); 
        const file = split[3]; 
            
        const url = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/${file}`;    

        const params = {
            method: "DELETE"            
        };

        try {
            
            const deleteResult = await fetch(url, params);        
    
            if(deleteResult.ok) {
                borrarOfferDb(id);
            }  

        } catch (error) {

             Swal.fire({
                type: 'error',
                icon: 'error',
                title: 'Something Wrong',                
            });
        }
           
     }

     const borrarOfferDb = async (id) => {

        try {

            const respuesta = await adminAxios.delete(`/offer/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`                          
                }
            });            
            
        } catch (error) {
            
            Swal.fire({
                type: 'error',
                icon: 'error',
                title: 'Something Wrong',                
            });
        }
     }

    return (
        
        <Grid container style={{paddingTop: 15, paddingLeft: '5%'}}>        
        {
            arreglo?.map((offer, index) => (
                <Card key={index} sx={{ maxWidth: 400}} style={{margin: 20, paddingTop: 10}}>

                    <CardMedia
                        sx={{ height: 140 }}
                        style={{marginTop: 10}}
                        image={offer.image}                   
                    />
                    <CardContent>

                    <Typography gutterBottom variant="h5" component="div">
                        {offer.title}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                        {offer.description}                   
                    </Typography>
                    <br/>
                    <Typography variant="body2" color="text.secondary">
                       <AccessAlarmsIcon/> {format(new Date(offer.dateStart), "d MMMM yyyy")} - {format(new Date(offer.dateEnd), "d MMMM yyyy")}   
                        <br/>                                   
                    </Typography>

                    </CardContent>

                    <CardActions>                        

                        <Button 
                            size="small" 
                            color='error'
                            onClick={() => onClickdeleteOffer(offer.id, offer.image)}
                        >
                            Delete
                        </Button>
                    </CardActions>

              </Card>

            ))
        }
       </Grid>
        
    )
}