import { uploadFile } from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;

export const config = { 
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_BUCKET_PUBLIC_KEY,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY,
}


export const handleUpload = async (file) => {

    uploadFile(file, config)
        .then(data => console.log(data))
        .catch(err => console.error(err))       
}


