import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export  function Shopping() { 
  
  return (
    <>       
       <Grid item xs={12} sm={6} md={4}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: '#1b3c6b',
                }}
              >
                <Box sx={{ opacity: '50%' }}></Box>
                <div>                                           

                  <Typography fontWeight="medium" variant='h3' align='center' gutterBottom>
                      Shopping
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    
                     Discover your next adventure through our curated travel recommendations! Explore destinations,
                      accommodations, and experiences via our affiliate links, supporting our site while you plan 
                      your perfect getaway

                     <br />
                     <br />

                       <Box 
                          component="a" 
                          href="https://my.linkpod.site/iya4tkl" 
                          target='_blank'  
                          sx={{ color: 'grey.400'}}                       
                        >
                          Classic Vacations                        
                      </Box>
                       <br />
                      
                       <Box 
                          component="a" 
                          href="https://www.projectexpedition.com/?utm_source=cleardestinations-41582-yulietseda-75516&utm_medium=referral&utm_campaign=tagent" 
                          target='_blank' 
                          sx={{ color: 'grey.400' }}                            
                        >
                          Project Expedition                  
                      </Box>
                       <br />
                       
                       <Box 
                          component="a" 
                          href="https://www.thewanderclub.com/YULIET82662" 
                          target='_blank'  
                          sx={{ color: 'grey.400' }}                         
                        >
                          The Wander Club                    
                      </Box>
                      <br />

                      <Box 
                          component="a" 
                          href="https://www.viator.com/?pid=P00006875&uid=U00492225&mcid=58086&currency=USD" 
                          target='_blank'  
                          sx={{ color: 'grey.400'}}                       
                        >
                          Viator                       
                      </Box>
                      <br />                      

                  </Typography>

                </div>
              </Stack>
            </Grid>
        </>
  );
}
