import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AppFooter from './AppFooter';
import AppAppBar from './AppAppBar';
import adminAxios from '../config/axios';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import {Shopping} from './Shopping.js'

const clear_destinations = require('../img/clear_destinations.jpg');



export  function Offers() {

  const [ offers, setOffers ] = useState([]);
  let location = useLocation();

  useEffect( () => {

    consultarOffers();    
  }, []);   
  
  const consultarOffers = async () => {
    
    try {
      
      const peticion = await adminAxios.get('/users_offers');
      setOffers(peticion.data.offers)

    } catch (error) {
      console.log(error)
    }
  }

  const Shared =  async(oferta) => {    

      const { title, description, image } = oferta; 
      const tipo = image.split('.');  
     
      const blob = new Blob([image]);     
      const file = new File([blob], `image.${tipo[4]}`, {type: `image.${tipo[4]}`});       
       
      if (navigator.share && navigator.canShare(file)) {

        navigator.share({
          file,
          title: title,
          text: description,
          url: location.pathname,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
     
   
  }

  return (
    <>
       <AppAppBar logo={true} />

        <Box
            id="highlights"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: 'white',
            }}
        >
          
        <Container
            sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >

            <Typography component="h2" variant="h3" sx={{ color: '#1b3c6b' }}>
                Travel Specials
            </Typography>
          
            <br />
            
            <Typography variant="body1" sx={{ color: '#1b3c6b' }}>
              Discover Exclusive Deals: Shop Through Our Affiliate Links Today
            </Typography>

            </Box>

        <Grid container spacing={2.5}>

          {offers.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: '#1b3c6b',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>

                  <CardMedia
                        sx={{ height: 140 }}
                        style={{marginTop: 10, marginBottom: 15}}
                        image={item.image}                   
                    />
                                    

                  <Typography fontWeight="medium" variant='h3' align='center' gutterBottom>
                    {item.title}
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                    <br/>
                    <br/>

                    <AccessAlarmsIcon/> 
                      {format(new Date(item.dateStart), "d MMMM yyyy")} - {format(new Date(item.dateEnd), "d MMMM yyyy")}   

                    <br/>

                  <IconButton 
                    style={{color: 'white'}}
                    onClick={() => Shared(item)}
                  >
                     <ShareIcon style={{marginTop: 10}}/> 
                    
                  </IconButton>                     

                  </Typography>

                </div>
              </Stack>
            </Grid>
          ))}

         <Shopping />
        </Grid>
      </Container>
    </Box>

    <AppFooter />
    </>
  );
}