import React, { useContext, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import { isAfter } from 'date-fns';
import TextField from '@mui/material/TextField';
import { Grid, IconButton, Input } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { config } from '../../config/awsS3';
import { uploadFile } from 'react-s3';
import Swal from "sweetalert2";
import adminAxios from '../../config/axios';
import { ApiContext } from '../../context/apiContext';
window.Buffer = window.Buffer || require("buffer").Buffer;


let offer = {
  title: '',
  description: '',
  dateStart: '',
  dateEnd: ''
};

export const NewOffer = () => {

  const fileRef = useRef(); 
  const navigate = useNavigate(); 
  const [ auth, setAuth] = useContext(ApiContext);   

  const leerDatos = (event) => {
    
      offer = {
        ...offer,
        [event.target.name]: event.target.value
      }
  }

  const validarCampos = () => {

    const {title, description, dateStart, dateEnd} = offer

    let valido = !title.length || !description.length || !dateStart.length || !dateEnd.length;

    return valido    
  }

  const onFileInputChange = async ({target}) => {

    if(target.files === 0) return;  
    
    const formValid = validarCampos();    
    
    if(formValid){

      Swal.fire({
        type: 'error',
        icon: 'error',
        title: 'All inputs is required',                
      });
      return
    }

    const is_after = isAfter(offer.dateEnd, offer.dateStart);

    if(!is_after){

        Swal.fire({
          type: 'error',
          icon: 'error',
          title: 'Dates Wrong',                
        })

      return
    }    

    await uploadFile(target.files[0], config)
      .then(data => {        
        
        offer = {
          ...offer,
          image: data.location 
        }

      })
    .catch(err => console.error(err))

    newOffer();    
  }

  const newOffer = async () => {

    if(auth.token !== '' && auth.auth){

      try {

        const newOffer = await adminAxios.post('/new_offer', offer, {
          headers: {
            Authorization: `Bearer ${auth.token}`                          
          }
        });         

        if(newOffer.status === 200){

          Swal.fire({
            type: 'success',
            icon: 'success',
            title: 'created successfully',                
          })

          navigate('/admin/offers/all');
        }            
        
      } catch (error) {

          Swal.fire({
            type: 'error',
            icon: 'error',
            title: 'Ooops',
            text: 'Something wrong'
        });

      }    
  
    } else {
      navigate('/');
    }
     
  }

  return (
    
    <Grid container style={{margin: 20}}>

      <TextField 
        type='text'       
        fullWidth
        placeholder='Input a tittle'
        label='Title'
        sx={{ border: 'none', mb: 1, mt: 2}}
        style={{paddingTop: 5}}
        name='title'
        onChange={leerDatos}
      />

      <TextField 
        type='text'        
        fullWidth
        multiline
        placeholder='Description'
        minRows={5}
        style={{paddingTop: 5}}
        name='description'
        onChange={leerDatos}
      />

      <input 
        type='file'        
        ref={fileRef}       
        onChange={onFileInputChange}
        style={{display: 'none'}}
        
      />      

      <TextField 
        type='date'        
        fullWidth
        // inputProps={{
        //   min: new Date().toISOString().split('T')[0], // Establece la fecha actual como valor mínimo
        // }}       
        sx={{ border: 'none', mb: 1}}
        style={{paddingTop: 15}}
        onChange={leerDatos}
        name='dateStart'
      />    

      <TextField 
        type='date'       
        fullWidth
        // inputProps={{
        //   min: new Date().toISOString().split('T')[0], // Establece la fecha actual como valor mínimo
        // }} 
        sx={{ border: 'none', mb: 1}}
        style={{paddingTop: 5}}
        onChange={leerDatos}
        name='dateEnd'
      />


      <Grid container orientation="horizontal"  justifyContent='space-between'>

          <IconButton
            color='primary'
            onClick={() => fileRef.current.click()} // simula el click sobre ese boton            
          >
            <UploadFileIcon fontSize='large'/>
          </IconButton>             

      </Grid>

    </Grid>
 
    
  )
}

